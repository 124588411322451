import axios from "axios";

const api = axios.create({
    accessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiaW52ZXN0MjAzMCIsIm5hbWUiOiJJbnZlc3QyMDMwIiwiQVBJX1RJTUUiOjE2Njc0MTc1ODZ9.aFWhPSNIHaUnmNhyVQNA9OWc9g9zc-P-dqTm91_9Xh8",
    baseURL: "https://crm.grupoinveste.pt/api",
  });
  

  api.interceptors.request.use(async config => {
    // Declaramos um token manualmente para teste.
   
   
      config.headers.authorization = `Bearer ${api.accessToken}`;
    

    return config;
  });
  export default api; 