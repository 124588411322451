import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import api from './services/Api';

function App() {
  const [staff, setUser] = useState();

  useEffect(() => {
    api
      .get("/staffs/1")
      .then((response) => setUser(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <p>Usuário: {staff?.firstname}</p>
        <p>Biografia:</p>
      </header>
    </div>
  );
}

export default App;
